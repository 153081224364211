import Script from "next/script";
import { useState } from "react";
import styled from "styled-components";
import { Container, ContainerContentStandard } from "../styles/container";
import { device } from "../styles/device";
import { WPBlock } from "./model/WPBlocks";
import { WPVideoEmbedBlock } from "./model/WPTalentpoolBlock";

interface EmbedBlockProps {
  block: WPBlock;
}

const TalentpoolBlockContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  padding-top: 32px;
  row-gap: 24px;

  @media ${device.tablet} {
    grid-template-columns: 1fr;
    column-gap: 32px;
    row-gap: 0px;
  }

  a {
    width: fit-content;
    display: block;
  }
`;

const VideoSectionContainer = styled.section`
  margin-top: 0px;
  width: 100%;

  .video-container {
    border-radius: 8px;
    @media ${device.tablet} {
      border-radius: 30px;
    }
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    width: 100%;
    margin-bottom: 32px;

    iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }
  a,
  button {
    margin-left: auto;
    margin-right: auto;
  }

  a,
  button {
    width: 100%;
    margin-bottom: 8px;
  }
`;

export const EmbedBlock = ({ block }: EmbedBlockProps) => {
  const [opacity, setOpacity] = useState(0);
  const attributes: WPVideoEmbedBlock = JSON.parse(block.attributesJSON);

  return (
    <Container backgroundColor="#FFFFFF">
      <ContainerContentStandard>
        <TalentpoolBlockContainer>
          <VideoSectionContainer>
            <div className="video-container">
              {attributes.url != null && attributes.url.includes("wistia") ? (
                <>
                  <Script src={attributes.url} />
                  <Script src="https://fast.wistia.com/assets/external/E-v1.js" />
                  <div
                    className="wistia_responsive_padding"
                    style={{ padding: "56.25% 0 0 0", position: "relative" }}
                  >
                    <div
                      className="wistia_responsive_wrapper"
                      style={{
                        height: "100%",
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "100%",
                      }}
                    >
                      <div
                        className="wistia_embed wistia_async_m3w5wqzo4v videoFoam=true"
                        style={{
                          height: "100%",
                          position: "relative",
                          width: "100%",
                        }}
                      >
                        <div
                          className="wistia_swatch"
                          style={{
                            height: "100%",
                            position: "absolute",
                            width: "100%",
                            left: 0,
                            top: 0,
                            opacity: opacity,
                            overflow: "hidden",
                            transition: "opacity 200ms",
                          }}
                        >
                          <img
                            src={attributes.url}
                            style={{
                              filter: "blur(5px)",
                              objectFit: "contain",
                              height: "100%",
                              width: "100%",
                            }}
                            alt=""
                            aria-hidden="true"
                            onLoad={() => setOpacity(1)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div
                  className="wistia_responsive_padding"
                  style={{ padding: "56.25% 0 0 0", position: "relative" }}
                >
                  <div
                    className="wistia_responsive_wrapper"
                    style={{
                      height: "100%",
                      position: "absolute",
                      left: 0,
                      top: 0,
                      width: "100%",
                    }}
                  >
                    <iframe
                      width="100%"
                      height="100%"
                      src={attributes.url}
                      title={attributes.url}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    />
                  </div>
                </div>
              )}
            </div>
            {/*        <StyledLink href={attributes?.buttonurl}>
              <Button primary size={ButtonSizes.l}>
                {attributes?.buttonlabel}
              </Button>
            </StyledLink> */}
          </VideoSectionContainer>
          {/*  <TalentpoolBlockText>
            {attributes?.description != null && (
              <TalentpoolBlockDescriptionMobile
                dangerouslySetInnerHTML={{ __html: attributes.description }}
              ></TalentpoolBlockDescriptionMobile>
            )}
          </TalentpoolBlockText> */}
        </TalentpoolBlockContainer>
      </ContainerContentStandard>
    </Container>
  );
};
