import { Job } from "../model/Job";
import { AktuelleNewsBlock } from "./AktuelleNewsBlock";
import { CenteredTextWithLogoBlock } from "./CenteredTextWithLogoBlock";
import { EmbedBlock } from "./EmbedBlock";
import { EventBannerBlock } from "./EventBannerBlock";
import { EventDatesBlock } from "./EventDatesBlock";
import { HighlightJobsBlock } from "./HighlightJobsBlock";
import { ImageLeftTextRightBlock } from "./ImageLeftTextRightBlock";
import { ImageRightTextLeftBlock } from "./ImageRightTextLeftBlock";
import { WPBlock } from "./model/WPBlocks";
import { NewsletterBlock } from "./NewsletterBlock";
import { TalentpoolBlock } from "./TalentpoolBlock";
import { TestimonialsBlock } from "./TestimonialsBlock";

interface BlockManagerProps {
  blocks: WPBlock[];
  blogPosts: any[];
  highlightJobs: Job[];
  LogoSvg: string;
  bucketUrl: string;
  acForm: any;
  testimonialsCompanies?: any[];
}

function BlockManager({
  blocks,
  blogPosts,
  highlightJobs,
  LogoSvg,
  bucketUrl,
  acForm,
  testimonialsCompanies,
}: BlockManagerProps) {
  return (
    <>
      {blocks.map((block, index) => {
        switch (block.name) {
          case "lazyblock/talentpool-teaser":
            return (
              <TalentpoolBlock
                key={block.name + index}
                block={block}
                index={index}
              ></TalentpoolBlock>
            );
          case "lazyblock/block-centered-text-with-logo":
            return (
              <CenteredTextWithLogoBlock
                key={block.name + index}
                block={block}
                LogoSvg={LogoSvg}
              ></CenteredTextWithLogoBlock>
            );
          case "lazyblock/newsletter":
            return (
              <NewsletterBlock
                key={block.name + index}
                acForm={acForm}
              ></NewsletterBlock>
            );
          case "lazyblock/event-banner":
            return (
              <EventBannerBlock
                key={block.name + index}
                block={block}
              ></EventBannerBlock>
            );
          case "lazyblock/aktuelle-news":
            return (
              <AktuelleNewsBlock
                key={block.name + index}
                title="Aktuelle News"
                blogPosts={blogPosts}
              ></AktuelleNewsBlock>
            );
          case "lazyblock/highlight-jobs":
            return (
              <HighlightJobsBlock
                key={block.name + index}
                highlightJobs={highlightJobs}
                bucketUrl={bucketUrl}
              ></HighlightJobsBlock>
            );
          case "lazyblock/image-left-text-right":
            return (
              <ImageLeftTextRightBlock
                key={block.name + index}
                block={block}
                LogoSvg={LogoSvg}
              ></ImageLeftTextRightBlock>
            );
          case "lazyblock/image-right-text-left":
            return (
              <ImageRightTextLeftBlock
                key={block.name + index}
                block={block}
                LogoSvg={LogoSvg}
              ></ImageRightTextLeftBlock>
            );
          case "lazyblock/speed-recruiting-event-termine": // speed-recruiting
            return (
              <EventDatesBlock
                key={block.name + index}
                block={block}
                LogoSvg={LogoSvg}
              ></EventDatesBlock>
            );
          case "lazyblock/testimonials":
            return (
              <TestimonialsBlock
                key={block.name + index}
                block={block}
                testimonials={testimonialsCompanies}
              ></TestimonialsBlock>
            );
          case "core/embed":
            return <EmbedBlock key={block.name + index} block={block} />;
          default:
            return;
        }
      })}
    </>
  );
}

export default BlockManager;
