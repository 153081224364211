import styled from "styled-components";
import SwiperCore, { Pagination } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { device } from "../../styles/device";
import Device from "../global/Device/Device";
import { TestimonialCardWP } from "./TestimonialCardWP";

const TestimonialsSectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  overflow: visible;
  margin-bottom: 64px;
  margin-top: 64px;

  h2 {
    margin-top: 0;
    margin-bottom: 8px;
  }
  h3 {
    margin-bottom: 48px;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    margin: 0px;
  }
`;

const SwiperWrapper = styled.div`
  .swiper {
    width: 100%;
    padding-top: 0;
    padding-bottom: 50px;
    overflow: visible;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 309px;
    height: auto;
    margin-right: 20px;

    @media ${device.tablet} {
      width: 600px;
    }
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    min-height: 100px;
  }

  .swiper-pagination-bullet-active {
    background: ${(props) => props.theme.colors.primary} !important;
  }
  .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet {
    background-color: #6e6e73;
  }
`;
interface SizeProps {
  fullSize: boolean;
}
const FeaturesSectionSwiperContent = styled.div<SizeProps>`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-top: 32px;
  overflow: visible;
  width: 365px;
  @media ${device.tablet} {
    width: 692px;
  }
  @media ${device.laptop} {
    padding: 0 25px;
    width: ${({ fullSize }) => (fullSize ? "1440px" : "1080px")};
  }
`;

const FeaturesSectionContent = styled.div<SizeProps>`
  margin-left: auto;
  margin-right: auto;
  width: 365px;
  max-width: 100%;
  text-align: center;
  @media ${device.tablet} {
    width: 692px;
    text-align: left;
  }
  @media ${device.laptop} {
    padding: 0 25px;
    width: ${({ fullSize }) => (fullSize ? "1440px" : "1080px")};
  }
`;

interface Props {
  headline: any;
  subheadline: any;
  testmonials: Testimonial;
  fullSize?: boolean;
}
interface Testimonial {
  edges: [
    item: {
      node: {
        ansprechpartner: string;
        text: string;
        agentur: any;
      };
    }
  ];
}

SwiperCore.use([Pagination]);

export const TestimonialsSection = ({
  headline,
  subheadline,
  testmonials,
  fullSize = false,
}: Props) => {
  return (
    <TestimonialsSectionContainer>
      <FeaturesSectionContent fullSize={fullSize}>
        {headline}
        {subheadline}
      </FeaturesSectionContent>

      <FeaturesSectionSwiperContent fullSize={fullSize}>
        <SwiperWrapper>
          <Device>
            {({ isMobile }) => (
              <Swiper
                grabCursor={true}
                slidesPerView={"auto"}
                pagination={{ clickable: true }}
                slideToClickedSlide
                cssMode={isMobile}
              >
                {testmonials?.edges?.map((item: any, index: any) => (
                  <SwiperSlide
                    key={
                      "swiper-slide-" +
                      item?.node?.testimonial?.ansprechpartner +
                      index
                    }
                  >
                    <TestimonialCardWP
                      key={
                        "swiper-card-" +
                        item?.node?.testimonial?.ansprechpartner +
                        index
                      }
                      agentur={item?.node?.testimonial?.agentur}
                      testimonial={item?.node?.testimonial?.text}
                      name={item?.node?.testimonial?.ansprechpartner}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </Device>
        </SwiperWrapper>
      </FeaturesSectionSwiperContent>
    </TestimonialsSectionContainer>
  );
};
