import dayjs from 'dayjs';
import Image from 'next/image';
import styled from 'styled-components';
import { Job } from '../../model/Job';
import { ButtonSizes, TextButton } from '../../styles/button';
import UploadSVG from '/public/svg/img.svg';
import HighlightSVG from '/public/svg/jobs/highlight.svg';

interface JobCardContainerProps {
    isColored?: boolean;
}

const JobCardContainer = styled.div<JobCardContainerProps>`
    position: relative;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 16px;
    cursor: pointer;
    transition: all .3s cubic-bezier(0,0,.5,1);
    box-shadow: 5px 5px 12px 0px rgb(247 192 58 / 50%);
    box-shadow: ${props => props.isColored ? "5px 5px 12px 0px rgba(247, 192, 58, 0.5);" : "2px 4px 12px rgb(0 0 0 / 8%)"} ;
    height: 100%;

    &:hover {
        box-shadow: ${props => props.isColored ? "0px 4px 22px rgba(247, 192, 58, 0.5);" : "2px 4px 16px rgb(0 0 0 / 16%)"} ;
        transform: scale3d(1.01,1.01,1.01);
    }
`;

const LogoPlaceholderContainer = styled.div`
  border-radius: 12px;
  height: 100%;
  width: 100%;
  border: none;
  background-color: ${(props) => props.theme.colors.lightBg};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
      height: 30px;
      width: 30px;
  }
`;

const JobCardContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

  .logo {
    position: relative;
    height: 45px;
    width: 45px;
  }
`;

const JobCardHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
`;

const JobCardHighlightJob = styled.div`
    display: flex;
    align-items: center;
    span {
        font-weight: 800;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.03em;
        color: ${props => props.theme.colors.secondary};
    }

    svg {
        margin-right: 6px;
    }
`;

const JobCardMain = styled.div`
    font-weight: 700;
    font-size: 17px;
    line-height: 23px;
    margin-bottom: auto;

    .job-title {
        margin-top: 0;
        margin-bottom: 8px;
        font-weight: 700;
        font-size: 17px;
        line-height: 23px;
        color: ${props => props.theme.colors.fontBlack};
    }
    .job-company {
        margin-top: 0;
        margin-bottom: 8px;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: ${props => props.theme.colors.secondary};
    }
    .job-city {
        margin-top: 0;
        margin-bottom: 22px; 
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: ${props => props.theme.colors.fontLight};
    }
`;

const JobCardFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    time {
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        color: #6E6E73;
    }
    button {
        padding: 0 !important;
        line-height: 18px;
    }
`;

interface JobCardProps {
    job: Job;
    smallCard: boolean;
    bucketUrl: string;
}

export default function JobCard({ job, smallCard, bucketUrl }: JobCardProps) {

    const getTimeAsString = () => {
        const days = dayjs(dayjs()).diff(job.createdAt, 'days');
        const hours = dayjs(dayjs()).diff(job.createdAt, 'hours');
        if (hours < 24) {
            if (hours < 1) {
                return `vor wenigen Minuten`;
            }
            if (hours === 1) {
                return `vor ${hours} Stunde`;
            } else {
                return `vor ${hours} Stunden`;
            }
        } else {
            if (days === 1) {
                return `vor ${days} Tag`;
            } else {
                return `vor ${days} Tagen`;
            }
        }
    }

    return (
        <JobCardContainer className='job-card' isColored={!smallCard && job.isColored}>
            <JobCardContent>
                <JobCardHeader>
                    <div className='logo'>
                        {
                            job.company?.logoUrl != null && job.company?.logoUrl != "" ?
                                <Image
                                    src={bucketUrl + job.company.logoUrl}
                                    alt="Company Card - Logo"
                                    layout="fill"
                                    objectFit='cover'
                                />
                                :
                                <LogoPlaceholderContainer>
                                    <UploadSVG />
                                </LogoPlaceholderContainer>
                        }
                    </div>
                    {job.isHighlight && <JobCardHighlightJob>
                        <HighlightSVG /> <span>Highlight Job</span>
                    </JobCardHighlightJob>}
                </JobCardHeader>
                <JobCardMain>
                    <p className='job-title'>{job.title}</p>
                    <p className='job-company'>{job.company?.name}</p>
                    <p className='job-city'>{job.city}</p>
                </JobCardMain>
                <JobCardFooter>
                    <time>{getTimeAsString()}</time>
                    <TextButton primary size={ButtonSizes.m}>
                        {"Weitere Infos >"}
                    </TextButton>
                </JobCardFooter>

            </JobCardContent>
        </JobCardContainer >
    )
}
