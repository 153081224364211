import styled from "styled-components";
import GridContainerAnimated from "../components/global/gsap/GridContainerAnimated";
import StyledLink from "../components/global/StyledLink";
import { Button, ButtonSizes } from "../styles/button";
import { device } from "../styles/device";
import { GridItem } from "../styles/grid/grid";
import { WPBlock } from "./model/WPBlocks";
import { WPCenteredTextWithLogoBlock } from "./model/WPTalentpoolBlock";

interface CenteredTextWithLogoProps {
  block: WPBlock;
  LogoSvg: any;
}

const GridItemContent = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 64px;
  padding-bottom: 64px;

  @media ${device.tablet} {
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 40px;
    padding-right: 40px;
  }
  @media ${device.laptop} {
    padding-top: 145px;
    padding-bottom: 145px;
  }

  a {
    width: fit-content;
    display: block;
    margin: auto;
  }
`;

const TalentpoolBlockText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 100%;
  word-break: break-word;

  a,
  button {
    width: 100%;
  }
  button {
    margin: 32px 0;
  }

  @media ${device.tablet} {
    width: fit-content;
    max-width: 743px;

    a,
    button {
      width: auto;
    }
    button {
      margin: 64px 0;
    }
  }
`;

const TalentpoolBlockHeadline = styled.h2`
  font-size: 36px;
  line-height: 40px;
  font-weight: 700;

  margin-top: 0;
  margin-bottom: 32px;

  @media ${device.tablet} {
    font-size: 56px;
    line-height: 60px;
    margin-bottom: 64px;
  }
`;
const TalentpoolBlockDescription = styled.div`
  font-size: 18px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 0px;
  color: ${(props) => props.theme.colors.fontBlack};

  p {
    margin: 0;
  }

  @media ${device.tablet} {
    margin-left: auto;
    margin-right: auto;
    font-size: 21px;
    line-height: 32px;
    font-weight: 400;
    margin-bottom: 32px;
  }
`;

const LogoWrapper = styled.div`
  svg {
    height: 60px;
    width: 60px;
  }
  margin-bottom: 12px;
`;

export const CenteredTextWithLogoBlock = ({
  block,
  LogoSvg,
}: CenteredTextWithLogoProps) => {
  const attributes: WPCenteredTextWithLogoBlock = JSON.parse(
    block.attributesJSON
  );

  return (
    <GridContainerAnimated>
      <GridItem>
        <GridItemContent>
          <LogoWrapper>
            <LogoSvg />
          </LogoWrapper>
          <TalentpoolBlockText>
            <TalentpoolBlockHeadline
              dangerouslySetInnerHTML={{ __html: attributes.title }}
            ></TalentpoolBlockHeadline>
            {attributes?.["description-1"] != null &&
            attributes?.["description-2"] != null &&
            attributes?.["description-1"].length > 0 ? (
              <>
                <TalentpoolBlockDescription
                  dangerouslySetInnerHTML={{
                    __html: attributes["description-1"],
                  }}
                ></TalentpoolBlockDescription>
                <StyledLink href={attributes?.buttonurl}>
                  <Button primary size={ButtonSizes.l}>
                    {attributes?.buttonlabel}
                  </Button>
                </StyledLink>
                <TalentpoolBlockDescription
                  dangerouslySetInnerHTML={{
                    __html: attributes["description-2"],
                  }}
                ></TalentpoolBlockDescription>
              </>
            ) : (
              <>
                <TalentpoolBlockDescription
                  dangerouslySetInnerHTML={{
                    __html: attributes.description,
                  }}
                ></TalentpoolBlockDescription>
                <StyledLink href={attributes?.buttonurl}>
                  <Button primary size={ButtonSizes.l}>
                    {attributes?.buttonlabel}
                  </Button>
                </StyledLink>
              </>
            )}
          </TalentpoolBlockText>
        </GridItemContent>
      </GridItem>
    </GridContainerAnimated>
  );
};
