import styled from "styled-components";
import { TestimonialsSection } from "../components/speed-recruiting/Testimonials";
import { Container, ContainerContentStandard } from "../styles/container";
import { WPTestimonialBlock } from "./model/WPTalentpoolBlock";

const BlogContentSection = styled.section`
  padding-top: 64px;
  padding-bottom: 64px;
`;

export const TestimonialsBlock = ({ block, testimonials }) => {
  const attributes: WPTestimonialBlock = JSON.parse(block.attributesJSON);

  return (
    <Container backgroundColor="#f5f5f7" overflow="hidden">
      <ContainerContentStandard>
        <BlogContentSection>
          <TestimonialsSection
            headline={<h2>{attributes?.title}</h2>}
            subheadline={<h3>{attributes?.subtitle}</h3>}
            testmonials={testimonials}
            fullSize={true}
          />
        </BlogContentSection>
      </ContainerContentStandard>
    </Container>
  );
};
