import Image from "next/image";
import styled from "styled-components";
import GridContainerAnimated from "../components/global/gsap/GridContainerAnimated";
import StyledLink from "../components/global/StyledLink";
import { ButtonSizes, TextButton } from "../styles/button";
import { device } from "../styles/device";
import { GridItem } from "../styles/grid/grid";
import { WPBlock } from "./model/WPBlocks";
import { WPImageData, WPTalentpoolBlock } from "./model/WPTalentpoolBlock";

interface ImageRightTextLeftBlock {
  block: WPBlock;
  LogoSvg: any;
}

const GridItemContent = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;

  @media ${device.tablet} {
    grid-template-columns: 2fr 3fr;
    column-gap: 16px;
    padding-left: 16px;
  }

  a {
    width: fit-content;
    display: block;
    margin: auto;
  }
`;

const TalentpoolBlockText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 743px;
  padding-top: 72px;
  padding-bottom: 64px;
  padding-left: 8px;
  padding-right: 8px;

  @media ${device.tablet} {
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 0px;
    padding-right: 0px;
  }
  @media ${device.laptop} {
    padding-top: 145px;
    padding-bottom: 145px;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const TalentpoolBlockHeadline = styled.h2`
  font-size: 28px;
  line-height: 34px;
  font-weight: 700;

  margin-top: 0;
  margin-bottom: 8px;

  @media ${device.tablet} {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 12px;
  }
`;
const TalentpoolBlockDescription = styled.div`
  font-size: 18px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 24px;
  color: ${(props) => props.theme.colors.fontBlack};

  p {
    margin: 0;
  }

  @media ${device.tablet} {
    font-size: 21px;
    line-height: 32px;
    font-weight: 400;
    margin-bottom: 32px;
  }
`;

const LogoWrapper = styled.div`
  svg {
    height: 60px;
    width: 60px;
  }
  margin-bottom: 12px;
`;

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  height: 200px;
  width: 100%;

  @media ${device.tablet} {
    height: 100%;
  }
`;

export const ImageRightTextLeftBlock = ({
  block,
  LogoSvg,
}: ImageRightTextLeftBlock) => {
  const attributes: WPTalentpoolBlock = JSON.parse(block.attributesJSON);
  const imageData: WPImageData =
    attributes?.image != null && attributes?.image !== ""
      ? JSON.parse(decodeURI(attributes.image))
      : null;

  return (
    <GridContainerAnimated>
      <GridItem>
        <GridItemContent>
          <TalentpoolBlockText>
            <LogoWrapper>
              <div className="flex justify-center">
                <LogoSvg />
              </div>
            </LogoWrapper>
            {attributes?.title != null && (
              <TalentpoolBlockHeadline
                dangerouslySetInnerHTML={{ __html: attributes.title }}
              ></TalentpoolBlockHeadline>
            )}
            {attributes?.description != null && (
              <TalentpoolBlockDescription
                dangerouslySetInnerHTML={{ __html: attributes.description }}
              ></TalentpoolBlockDescription>
            )}
            <StyledLink href={attributes?.buttonurl}>
              <TextButton primary size={ButtonSizes.m}>
                {attributes?.buttonlabel}
              </TextButton>
            </StyledLink>
          </TalentpoolBlockText>

          <ImageContainer>
            {imageData != null && (
              <Image
                src={imageData.url}
                alt={imageData.alt}
                layout="fill"
                objectFit={
                  attributes?.objectFitCover === true ? "cover" : "contain"
                }
                priority={true}
              />
            )}
          </ImageContainer>
        </GridItemContent>
      </GridItem>
    </GridContainerAnimated>
  );
};
