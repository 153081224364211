import styled from 'styled-components';
import HighlightJobsSwiper from '../components/job/HighlightJobsSwiper';
import { Container, ContainerContentStandard } from '../styles/container';

const JobConentSection = styled.section`
    padding-top: 64px;
    padding-bottom: 64px;

    h2 {
        font-size: 32px;
        line-height: 1.125;
        font-weight: 800;
        margin-top: 0;
        margin-bottom: 24px;
    }
`

export const HighlightJobsBlock = ({ highlightJobs, bucketUrl }) => {

    return (
        <Container backgroundColor='#f5f5f7' overflow="hidden">
            <ContainerContentStandard>
                <JobConentSection>
                    {highlightJobs != null && highlightJobs?.length > 0 &&
                        <div>
                            {highlightJobs != null && highlightJobs?.length > 0 &&
                                <div>
                                    <h2>Die Top-Jobs aus unserer Jobbörse</h2>
                                    <HighlightJobsSwiper topJobs={highlightJobs} bucketUrl={bucketUrl} />
                                </div>
                            }
                        </div>
                    }
                </JobConentSection>
            </ContainerContentStandard>
        </Container>
    );
};
