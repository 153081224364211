import React from 'react';
import styled from 'styled-components';
import PostTiles from '../components/blog/PostTiles';
import { BlogContentGrid, TileSizes } from '../styles/blog/blog';
import { Container, ContainerContentNarrow } from '../styles/container';

const BlogContentSection = styled.section`
    padding-top: 64px;
    padding-bottom: 64px;

    h2 {
        font-size: 32px;
        line-height: 1.125;
        font-weight: 800;
        margin-top: 0;
        margin-bottom: 24px;
    }
`

export const AktuelleNewsBlock = ({ title, blogPosts }) => {

    const newestPosts = blogPosts.slice(0, 3);

    return (
        <Container backgroundColor='#f5f5f7'>
            <ContainerContentNarrow>
                <BlogContentSection>
                    <h2>{title}</h2>
                    <BlogContentGrid role="list" twoColumns={false}>
                        <PostTiles posts={newestPosts} size={TileSizes.s} />
                    </BlogContentGrid>
                </BlogContentSection>
            </ContainerContentNarrow>
        </Container>
    );
};
