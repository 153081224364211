import PostTile from './PostTile'

export default function PostTiles({ posts, size }) {

  return (
    <>
      {posts.map(({ node }) => (
        <PostTile
          key={node.slug}
          title={node.title}
          coverImage={node.featuredImage?.node}
          date={node.date}
          author={node.author?.node}
          slug={node.slug}
          excerpt={node.excerpt}
          categories={node.categories}
          size={size}
        />
      ))}
    </>
  )
}
