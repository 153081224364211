import Image from 'next/image';
import styled from 'styled-components';
import GridContainerAnimated from '../components/global/gsap/GridContainerAnimated';
import StyledLink from '../components/global/StyledLink';
import { Button, ButtonSizes } from '../styles/button';
import { device } from '../styles/device';
import { GridItem } from '../styles/grid/grid';
import { WPBlock } from './model/WPBlocks';
import { WPEventBannerBlock, WPImageData } from './model/WPTalentpoolBlock';

interface EventBannerBlockProps {
    block: WPBlock;
}

const GridItemContent = styled.div`
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr;
    padding-left: 0;
    position: relative;
    grid-auto-rows: minmax(min-content, max-content);

    @media ${device.tablet} {
        padding-left: 40px;
        grid-template-columns: 3fr 2fr 2fr;
    }
    @media ${device.laptop} {
        grid-template-columns: 3fr 2fr 2fr;
    }

    a {
        width: fit-content;
    }
`;

const TalentpoolBlockText = styled.div`
    display: flex;
    flex-direction: column;
    width: fit-content;
    padding: 30px;
    text-align: center;
    align-items: center;
    justify-self: center;
    
    @media ${device.tablet} {
        text-align: left;
        align-items: flex-start;
        padding-top: 48px;
        padding-bottom: 48px;
        padding-left: 0;
        padding-right: 0;
        justify-self: flex-start;
    }
`;

const TalentpoolBlockHeadline = styled.h2`
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;

    margin-top: 0;
    margin-bottom: 12px;
    
    @media ${device.tablet} {
        font-size: 38px;
        line-height: 54px;
        margin-bottom: 16px;
    }
`;
const EventBannerImageContainer = styled.div`
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    min-height: 200px;
    @media ${device.tablet} {
        min-height: unset;
    }
`;
const TalentpoolBlockDescription = styled.div`
    position: relative;
    font-size: 18px;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 24px;
    color: ${(props) => props.theme.colors.fontBlack};
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    text-align: center;
    
    @media ${device.tablet} {
        text-align: left;
    }

    p {
        margin: 0;
        em {
            display: flex;
            font-size: 14px;
            line-height: 23px !important;
            margin-top: 12px;
        }
    }

    @media ${device.tablet} {
        font-size: 21px;
        line-height: 32px;
        font-weight: 400;
        padding: 24px 0;
        margin-bottom: 0;
    }
`;

export const EventBannerBlock = ({ block }: EventBannerBlockProps) => {
    const attributes: WPEventBannerBlock = JSON.parse(block.attributesJSON);
    const imageData: WPImageData = (attributes?.image != null && attributes?.image !== "") ? JSON.parse(decodeURI(attributes.image)) : null;

    return (
        <GridContainerAnimated>
            <GridItem color={"#FDC228"}>
                <GridItemContent>
                    <TalentpoolBlockText>
                        <TalentpoolBlockHeadline>{attributes?.title}</TalentpoolBlockHeadline>
                        <StyledLink href={attributes?.buttonurl}>
                            <Button primary size={ButtonSizes.m}>{attributes?.buttonlabel}</Button>
                        </StyledLink>
                    </TalentpoolBlockText>
                    {attributes?.termine != null &&
                        <TalentpoolBlockDescription dangerouslySetInnerHTML={{ __html: attributes.termine }}></TalentpoolBlockDescription>
                    }
                    <EventBannerImageContainer>
                        {imageData != null &&
                            <Image
                                src={imageData.url}
                                alt={imageData.alt}
                                layout="fill"
                                objectFit="cover"
                            // blurDataURL="data:..." automatically provided
                            // placeholder="blur"
                            />
                        }
                    </EventBannerImageContainer>
                </GridItemContent>
            </GridItem>
        </GridContainerAnimated>);
};
