import Link from 'next/link';
import { PostContainer, TileDescription, TileDescriptionCategory, TileDescriptionHeadline, TileMedia, TileTimestamp } from '../../styles/blog/blog';
import CoverImage from './CoverImage';
import Date from './Date';

export default function PostTile({
  title,
  coverImage,
  date,
  excerpt,
  author,
  slug,
  size,
  categories
}) {
  let postCategory = 'Update';
  const categoriesEdges = categories?.edges;
  if (categoriesEdges != null && categoriesEdges?.length > 0) {
    postCategory = categoriesEdges[0]?.node?.name;
  }
  return (
    <PostContainer size={size}>
      <Link href={`/blog/${slug}`} passHref={true} aria-label={title}>
        <div className="inner-link-wrapper">
          <TileMedia className='tile-media' size={size}>
            {coverImage && (
              <CoverImage title={title} coverImage={coverImage} slug={slug} />
            )}
          </TileMedia>
          <TileDescription size={size}>
            <div>
              <TileDescriptionCategory size={size}>
                {postCategory}
              </TileDescriptionCategory>
              <TileDescriptionHeadline size={size}>
                {title}
              </TileDescriptionHeadline>
            </div>
            <TileTimestamp>
              <Date dateString={date} />
            </TileTimestamp>
          </TileDescription>
        </div>
      </Link>
    </PostContainer>
  )
}
