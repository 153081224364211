import Image from "next/image";
import styled from "styled-components";
import StyledLink from "../components/global/StyledLink";
import { Button, ButtonSizes } from "../styles/button";
import { Container, ContainerContentStandard } from "../styles/container";
import { device } from "../styles/device";
import { WPBlock } from "./model/WPBlocks";
import { WPImageData, WPTalentpoolBlock } from "./model/WPTalentpoolBlock";

interface TalentpoolBlockProps {
  block: WPBlock;
  index: number;
}

const TalentpoolBlockContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 32px;
  row-gap: 20px;

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
    column-gap: 32px;
    row-gap: 0px;
  }

  a {
    width: fit-content;
    display: block;
  }
`;
const TalentpoolBlockText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: fit-content;
  padding-top: 16px;
  padding-bottom: 16px;

  @media ${device.tablet} {
    padding-top: 32px;
    padding-bottom: 32px;
  }
`;

const TalentpoolBlockHeadline = styled.h2`
  font-size: 36px;
  line-height: 40px;
  font-weight: 700;

  margin-top: 0;
  margin-bottom: 12px;

  @media ${device.tablet} {
    font-size: 56px;
    line-height: 60px;
    margin-bottom: 16px;
  }
`;
const TalentpoolBlockDescription = styled.div`
  font-size: 18px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 24px;
  color: ${(props) => props.theme.colors.fontBlack};

  p {
    margin: 0;
  }

  @media ${device.tablet} {
    font-size: 21px;
    line-height: 32px;
    font-weight: 400;
    margin-bottom: 32px;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  height: 200px;
  width: 100%;
  border-radius: 16px;

  img {
    border-radius: 16px;
  }

  @media ${device.tablet} {
    height: 100%;
  }
`;

export const TalentpoolBlock = ({ block, index }: TalentpoolBlockProps) => {
  const attributes: WPTalentpoolBlock = JSON.parse(block.attributesJSON);
  const imageData: WPImageData =
    attributes?.image != null && attributes?.image !== ""
      ? JSON.parse(decodeURI(attributes.image))
      : null;
  return (
    <Container backgroundColor="#FFFFFF">
      <ContainerContentStandard>
        <TalentpoolBlockContainer>
          <TalentpoolBlockText>
            {attributes?.title != null && (
              <TalentpoolBlockHeadline
                dangerouslySetInnerHTML={{ __html: attributes.title }}
              ></TalentpoolBlockHeadline>
            )}
            {attributes?.description != null && (
              <TalentpoolBlockDescription
                dangerouslySetInnerHTML={{ __html: attributes.description }}
              ></TalentpoolBlockDescription>
            )}
            <StyledLink href={attributes?.buttonurl}>
              <Button primary size={ButtonSizes.m}>
                {attributes?.buttonlabel}
              </Button>
            </StyledLink>
          </TalentpoolBlockText>
          <ImageContainer>
            {imageData != null && (
              <Image
                src={imageData.url}
                alt={imageData.alt}
                layout="fill"
                objectFit="cover"
                loading={index < 2 ? "eager" : "lazy"}
                priority={index < 2}
              />
            )}
          </ImageContainer>
        </TalentpoolBlockContainer>
      </ContainerContentStandard>
    </Container>
  );
};
