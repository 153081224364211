import React from 'react';
import styled from 'styled-components';
import StyledLink from '../components/global/StyledLink';
import { Button, ButtonSizes } from '../styles/button';
import { device } from '../styles/device';
import { WPBlock } from './model/WPBlocks';
import { WPSpeedRecruitingBlock } from './model/WPTalentpoolBlock';

interface CenteredTextWithLogoProps {
    block: WPBlock;
    LogoSvg: any;
}

const GridItemContent = styled.div`
    box-sizing: border-box;
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 32px;
    padding-bottom: 32px;

    @media ${device.tablet} {
        padding-left: 40px;
        padding-right: 40px;
    }

    a {
        width: fit-content;
        display: block;
        margin: auto; 
    }
`;

const TalentpoolBlockText = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    max-width: 743px;

    @media ${device.tablet} {
        width: fit-content;
    }
`;

const EventEyebrow = styled.p`
    font-size: 17px;
    line-height: 23px;
    color: ${(props) => props.theme.colors.eyebrow};
    margin-bottom: 16px;
    margin-top: 0;
`;

const TalentpoolBlockHeadline = styled.div`
    font-size: 28px;
    line-height: 34px;
    font-weight: 700;

    h2 {
        font-size: 28px;
        line-height: 34px;
        font-weight: 700;
        margin: 0;
    }

    margin-top: 0;
    margin-bottom: 12px;
    
    @media ${device.tablet} {
        h2 {
            font-size: 42px;
            line-height: 50px;
            margin: 0;
        }
        margin-bottom: 16px;
    }
`;
const TalentpoolBlockDescription = styled.div`
    font-size: 18px;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 24px;
    color: ${(props) => props.theme.colors.fontBlack};

    p {
      margin: 0;
    }
    
    @media ${device.tablet} {
        font-size: 21px;
        line-height: 32px;
        font-weight: 400;
        margin-bottom: 32px;
    }
`;

const LogoWrapper = styled.div`
    svg {
        height: 60px;
        width: 60px;
    }
    margin-bottom: 12px;
`;

const EventDatesContainer = styled.div`
    grid-column: span 12;
    background-color: #F5F5F7;
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 30px;
    z-index: 0;
    margin-top: 64px;
    margin-bottom: 64px;
`;

const EventDatesTable = styled.table`
    margin: auto;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 32px;

    
    th {
        font-size: 14px;
        color: ${(props) => props.theme.colors.fontBlack};
        font-weight: 400;
    }
    td:first-child, th:first-child {
        text-align: left;
    }
    td:last-child, th:last-child {
        text-align: right;
    }
    td {
        font-size: 19px;
        line-height: 27px;
        font-weight: 600;
        color: ${(props) => props.theme.colors.fontBlack};
        padding-bottom: 10px;
        padding-top: 16px;
    }
    tr {
        border-bottom: 1px solid #d2d2d7;
    }
    tr:last-child{
        border-bottom: none;
      }

     @media ${device.tablet} {
        width: 486px;

        td {
            font-size: 21px;
            line-height: 29px;
        }
    }
`;

export const EventDatesBlock = ({ block, LogoSvg }: CenteredTextWithLogoProps) => {
    const attributes: WPSpeedRecruitingBlock = JSON.parse(block.attributesJSON);

    return (
        <EventDatesContainer>
            <GridItemContent>
                <LogoWrapper>
                    <LogoSvg />
                </LogoWrapper>
                <TalentpoolBlockText>
                    <EventEyebrow>{attributes.eyebrow}</EventEyebrow>
                    <TalentpoolBlockHeadline dangerouslySetInnerHTML={{ __html: attributes.title }}></TalentpoolBlockHeadline>
                    {attributes?.description != null &&
                        <TalentpoolBlockDescription dangerouslySetInnerHTML={{ __html: attributes.description }}></TalentpoolBlockDescription>
                    }
                    {attributes?.events != null &&
                        (
                            <EventDatesTable>
                                <thead>
                                    <tr>
                                        <th>Standort</th>
                                        <th>Termin</th>
                                    </tr>
                                </thead>
                                <tbody dangerouslySetInnerHTML={{ __html: attributes.events }}>
                                </tbody>
                            </EventDatesTable>
                        )
                    }
                    <StyledLink href={attributes?.buttonurl}>
                        <Button primary size={ButtonSizes.m}>{attributes?.buttonlabel}</Button>
                    </StyledLink>
                </TalentpoolBlockText>
            </GridItemContent>
        </EventDatesContainer>
    );
};
