import Image from 'next/image';
import React from 'react';
import styled from 'styled-components';
import { device } from '../../styles/device';

const CompanyIcon = styled.div`
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  height: 120px;
  width: 160px;

  @media ${device.tablet} {
      height: 150px;
      width: 200px;
      margin-bottom: 32px;
  }
`;
const TestimonialCardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: ${(props) => props.theme.borderRadiusBig};
  padding: 26px;
  background-color: #FFFFFF;
  box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
  /* max-height: 500px; */
  width: auto;

  p {
    color: ${(props) => props.theme.colors.fontBlack};
    font-size: 17px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 24px;

    @media ${device.tablet} {
      font-size: 18px;
    }
  }

  strong {
    color: ${(props) => props.theme.colors.fontBlack};
    font-weight: 700;
    margin-bottom: 4px;
  }

  span {
    color: ${(props) => props.theme.colors.fontLight};
    font-weight: 400;
  }
  
  svg {
    height: 60px;
    width: auto;
    margin: auto;
  }

  @media ${device.tablet} {
    padding: 32px;
    grid-column: 'span 1';
  }
`;

interface DisciplinCardProps {
  agentur: any;
  testimonial: string;
  name: string;
}

export const TestimonialCardWP: React.FunctionComponent<DisciplinCardProps> = ({ agentur, testimonial, name }: any) => {
  const theAgentur = agentur?.length === 1 ? agentur[0].agentur : null;
  return (
    <TestimonialCardContainer>
      <CompanyIcon>
        {theAgentur?.logo?.sourceUrl != null &&
          <Image
            src={theAgentur?.logo?.sourceUrl}
            alt={agentur.name + 'Logo Image'}
            layout="fill"
            objectFit='contain'
          />
        }
      </CompanyIcon>
      <p>{testimonial}</p>
      <strong>{name}</strong>
      {
        theAgentur != null &&
        <span>{theAgentur?.name}</span>
      }
    </TestimonialCardContainer >
  )
}
