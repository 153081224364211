import styled from "styled-components";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Job } from "../../model/Job";
import { device } from "../../styles/device";
import Device from "../global/Device";
import StyledLink from "../global/StyledLink";
import JobCard from "./JobCard";

SwiperCore.use([Pagination, Navigation]);

const SwiperWrapper = styled.div`
  .swiper {
    width: 100%;
    padding-top: 0;
    padding-bottom: 50px;

    overflow: visible;
    margin-left: 0;
    margin-right: auto;
    width: 365px;
    margin-bottom: 64px;
    @media ${device.tablet} {
      width: 692px;
    }
    @media ${device.laptop} {
      width: 1080px;
    }
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;

    width: 309px;
    height: auto;
    margin-right: 20px;
    margin-bottom: 32px;
    @media ${device.tablet} {
      width: 500px;
    }

    /*         @media ${device.tablet} {
            width: 692px;
        }
        
        @media ${device.laptop} {
            width: 1080px;
        } */
  }

  .swiper-slide img {
    display: block;
    width: 100%;
  }

  .swiper-pagination-bullet-active {
    background: ${(props) => props.theme.colors.secondary} !important;
  }
  .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet {
    background-color: #6e6e73;
  }
  .swiper-button-next,
  .swiper-button-prev {
    height: 44px;
    width: 44px;
    text-align: center;
    bottom: -10px;
    top: initial;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    height: 15px;
    width: 15px;
    font-size: 16px;
    font-weight: 800;
  }

  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: 25px;
    color: ${(props) => props.theme.colors.fontBlack};
    border: 2px solid ${(props) => props.theme.colors.fontBlack};
    border-radius: 50%;
  }
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: 25px;
    color: ${(props) => props.theme.colors.fontBlack};
    border: 2px solid ${(props) => props.theme.colors.fontBlack};
    border-radius: 50%;
  }
`;

interface Props {
  topJobs: Job[];
  bucketUrl: string;
}

export default function HighlightJobsSwiper({ topJobs, bucketUrl }: Props) {
  return (
    <SwiperWrapper>
      <Device>
        {({ isMobile }) => (
          <Swiper
            cssMode={isMobile}
            grabCursor={true}
            slidesPerView={"auto"}
            pagination={{ clickable: true, dynamicBullets: true }}
            navigation={true}
            className="jobs-swiper"
          >
            {topJobs?.map((job, index) => {
              return (
                <SwiperSlide key={job.title + index}>
                  <StyledLink href={"/jobs/" + job.slug}>
                    <JobCard job={job} smallCard={true} bucketUrl={bucketUrl} />
                  </StyledLink>
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </Device>
    </SwiperWrapper>
  );
}
